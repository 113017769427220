<template functional>
  <section class="services">
    <!-- <b-col sm="12"> -->
    <b-card tag="article" class="card--services card--specific-service">
      <template>
        <!--v-slot:header -->
        <!-- <router-link to="/services/service-details"> -->
        <!-- <section class="img-wrap ratio-75">
          <div class="img-content"> -->
        <section class="sub-service__img">
          <div class="sub-service__img--div">
            <b-img
              height="100"
              fluid
              :src="props.service.image ? props.service.image.path : ''"
            ></b-img>
          </div>
        </section>
        <!-- </div>
        </section> -->
        <h4 class="card-title text-center mt-4">
          {{ props.service.title }}
        </h4>
        <!-- </router-link> -->
      </template>
      <section class="card-text">
        <b-card-text>
          {{ props.service.description }}
        </b-card-text>
      </section>
    </b-card>
    <!-- </b-col> -->
  </section>
</template>

<script>
export default {
  name: "SpecificServiceCard",
  props: {
    service: {
      type: Object,
      default: () => {
        return {
          title: "",
          description: "",
          image: null
        };
      }
    }
  }
};
</script>
